const contracts = {
  cohort: {
    1: '0x87e1f628225c170a5C0Bf895580686430DEb3322',

    5: '0x57fFdCCDf010262a358DA540a991F5ACCe010CAE',
    56: '0xa7c8Dd26eB99534Dd848278e53CB7e260Ea83CE1',
    97: '0x41B124571682CaFaF7Cf980CBD560447EC0E2bC7',
    57000: '0xc40af0F0e96c78C9dEA01eC453696BaE43D7C68d',
    570: '0xed844Ef11124F95B178b5843eCaBC2f6EC7dFe7A',
  },
  multiCall: {
    1: '0x1F98415757620B543A52E61c46B32eB19261F984',
    11155111: '0xcA11bde05977b3631167028862bE2a173976CA11',
    56: '0xfF6FD90A470Aaa0c1B8A54681746b07AcdFedc9B',
    97: '0x8F3273Fb89B075b1645095ABaC6ed17B2d4Bc576',
    57000: '0xcA11bde05977b3631167028862bE2a173976CA11',
    570: '0xcA11bde05977b3631167028862bE2a173976CA11',
  },
  premium: {
    1: '0x31d20E7d8bEaF36Cfe8369EB8FBC846B5466c983',
    11155111: '0xcb2B848AF2C87C6a5B213C6dB4259CfA95A7c7E3',
    56: '0xd85D5144718982bdD442E67d9810151a6AdF31c2',
    97: '0x59122F15f2cB0fB6a3bd094B761e6dcc771EE4E9',
    57000: '0x34c76160d79C6d0C678b0B48d40eC5ddc895f4ED',
    570: '0xc94002a997d4e4E90D423778170588f695c5f242',
  },
  capitalAgent: {
    1: '0xd49AEEAB29e098B18f6494b34C9279fe858c60ce',
    11155111: '0x11819a1eB9373F86f4E1f7dAE8508E678BAF5D7B',
    56: '0x62151616725e5bF4ca6Dc310b1fd4a5bf63fDB6c',
    97: '0x84Ff1D5934608201D116b8B99aA5dB8cBCEb891b',
    57000: '0x2aAb17643960Ef1909522F3F8F706c587636FE27',
    570: '0xB754842C7b0FA838e08fe5C028dB0ecd919f2d30',
  },
  ssrp: {
    1: '0xe86B104B12546BF9c87329f8936CaF2c4340b728',

    5: '0x35b3685F04f894D83101dB7b47cd1e89ce976782',
    56: '0x62e1D28f3204962852976983cD575Fc2741bfE19',
    97: '0xeF6f486791DE1a2F10459383Caa4114163960424',
    57000: '0xc40af0F0e96c78C9dEA01eC453696BaE43D7C68d',
    570: '0xed844Ef11124F95B178b5843eCaBC2f6EC7dFe7A',
  },
  uno: {
    1: '0x474021845C4643113458ea4414bdb7fB74A01A77',
    11155111: '0xF75C8E49831c055a88957adbc97450f778460FD9',
    56: '0x62e1D28f3204962852976983cD575Fc2741bfE19',
    97: '0x28E8bf707CD1C9D1a9F9fEd5563BB2b399dce90E',
    57000: '0x1E61F32cBc30d919AdC92CA1eD92dA3fd115a530',
    570: '0x570baA32dB74279a50491E88D712C957F4C9E409',
  },
  migration: {
    97: '0x174ea72cCD4229878c98027e6e9C4707C7C59CBc',
    56: '0x2f54c54D250Fc77Ac60021fAddd2D6C3F13A5AF0',
  },
  usdc: {
    1: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
    11155111: '0xdb2587deb089c8f914ba6fedf1e3d3cb8660a015',
    97: '0x62562D21F071141DD0f0821ad639c1cBFaAd4145',
    57000: '0xa68E417905ACdEdC32ae8DA9113a6d4d2b6B2F30',
    570: '0x368433CaC2A0B8D76E64681a9835502a1f2A8A30',
  },
  usdt: {
    1: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    4: '0x40c035016AD732b6cFce34c3F881040B6C6cf71E',
    5: '0xB6b67A0b6B3e627d9e9aD6232c1CEf3cBb719620',
    56: '0x68C34F54E63664019f59029b9dE344d6219C7758',
    97: '0x892528707DF43773782F2564FbCd5Dc7441B86EB',
    570: '0x28c9c7Fb3fE3104d2116Af26cC8eF7905547349c',
  },
  salesPolicy: {
    1: '0x6E1A8f3466eb69eb298b62192414B267a47d701A',
    11155111: '0x0d9E62654EDAc0efFB2262Cfb9F68fdb9Fa8E80E',
    56: '0xd85D5144718982bdD442E67d9810151a6AdF31c2',
    97: '0x6183e57F4BCE4A07406349B959e43213834381A0',
    57000: '0x1A2e278c7231557DBb2cA3eEeC9df4985Ace2404',
    570: '0x5B170693E096D8602f970757068859b9A117fA6D',
  },
  payoutRequestETH: {
    1: '0x1f209E24f0928E2A89A5155a9D11f301f5940c3e',
    11155111: '0x4009dE83e501057089B9B780990c54005b76a2f0',
    570: '0xCaB9faf23a9803352f3f61Bc23782A9eb4a90fcC',
  },
  payoutRequestUNO: {
    1: '0xA95cb0641b7dC9141339FC3AFC293eEEf74f7cE7',
    11155111: '0x84eb9F90A31A9b5fC2C46a42ae19DE259832b635',
    570: '0x2ACB9184543a537F182Ecb6E2c224b0b09614cB1',
  },
  payouRequestUSDC: {
    1: '0xf99a0C6d2935AFB3BB6711A4C3251b55F3542451',
    11155111: '0x9a752bc5Af86ec2AAAfed8E18751960d4e348752',
    570: '0x1024a3a9D000aD3cd6Ac88490F86aD9FEAef7DCA',
  },
  payoutRequestUSDT: {
    1: '0x837ABAA46F80624b2c3aAb01f0e38E426552DA6b',
    11155111: '0x61892783c24672e6705124Ea1273823b22426B99',
    570: '0xAFe952B8afF0cbC96121CfA78174094629528D2c',
  },
}

export default contracts
