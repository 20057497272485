import poolsConfig from '@/config/constants/pools'
import BigNumber from 'bignumber.js'
import {
  getBep20Contract,
  getCapitalAgentContract,
  getLpContract,
  getRiskPoolsContractV2,
} from '@/utils/contractHelpers'
import { getAddress } from '@/utils/addressHelpers'
import { getBalanceAmount } from '@/utils/formatBalance'
import { lpTokens } from '@/config/constants/tokens'
import { simpleRpcProvider } from '@/utils/providers'
import { getRpcUrl } from '@/utils/getRpcUrl'
import { JsonRpcProvider } from '@ethersproject/providers'
import { Contract, ethers } from 'ethers'
import { getContract } from '@/utils'
import ssipAbi from '@/config/abi/SSIPAbi.json'
import { AppNetworkId } from '@/config/chains'

export const fetchPoolsTotalStaking = async () => {
  const _calls = poolsConfig.map(async (_pool) => {
    let contract: Contract

    if (_pool.oldPool) {
      contract = getRiskPoolsContractV2(_pool.poolId)
    } else {
      contract = getBep20Contract(
        _pool.stakingToken.address[_pool.chainId],
        new JsonRpcProvider(getRpcUrl(_pool.chainId)),
      )
    }

    if (_pool.ethNativePool) {
      return simpleRpcProvider.getBalance(getAddress(_pool.riskPoolAddress))
    } else if (_pool.ethPool || (!_pool.ethPool && !_pool.oldPool)) {
      // TODO: Always read from capital agent once all pools are updated
      if (_pool.chainId === AppNetworkId.ROLLUX) {
        const capitalAgent = getCapitalAgentContract(_pool.chainId)

        const poolInfo = await capitalAgent.getPoolInfo(_pool.contractAddress[_pool.chainId])

        const totalCapital = poolInfo[0] as ethers.BigNumber
        const pendingCapital = poolInfo[4] as ethers.BigNumber

        return totalCapital.sub(pendingCapital)
      } else {
        const riskPoolAddress = _pool.riskPoolAddress[_pool.chainId]

        return contract.balanceOf(riskPoolAddress)
      }
    } else {
      return contract.totalSupply()
    }
  })

  const ttStakedForEth = await Promise.all(_calls).then((value) => {
    return value
  })

  const ttStakedForEthInJson = ttStakedForEth.map((_) => _.toJSON())

  return poolsConfig.map((p, index) => ({
    poolId: p.poolId,
    totalStaked: ttStakedForEthInJson[index],
  }))
}

export const fetchPoolsMaxSize = async () => {
  const max = [
    {
      type: 'BigNumber',
      hex: '0x00',
    },
    {
      type: 'BigNumber',
      hex: '0x00',
    },
    {
      type: 'BigNumber',
      hex: '0x00',
    },
    {
      type: 'BigNumber',
      hex: '0x00',
    },
    {
      type: 'BigNumber',
      hex: '0x00',
    },
    {
      type: 'BigNumber',
      hex: '0x00',
    },
    {
      type: 'BigNumber',
      hex: '0x00',
    },
    {
      type: 'BigNumber',
      hex: '0x07695a92c20d6fe00000',
    },
    {
      type: 'BigNumber',
      hex: '0x22f0aafd00887d200000',
    },
    {
      type: 'BigNumber',
      hex: '0x3f870857a3e0e3800000',
    },
  ]

  return poolsConfig.map((p, index) => ({
    poolId: p.poolId,
    maxSize: max[index],
  }))
}

export const fetchLpAmount = async () => {
  const returnRes = {}
  const _calls = lpTokens.map(async (element) => {
    returnRes[`${element.symbol}`] = 0
    const contract = await getLpContract(element.address)
    return contract.lpPriceUno()
  })

  const prices = await Promise.all(_calls).then((res) => {
    return res
  })
  for (let i = 0; i < lpTokens.length; i++) {
    returnRes[`${lpTokens[i].symbol}`] = getBalanceAmount(
      new BigNumber(prices[i].toString()),
      lpTokens[i].decimals,
    ).toNumber()
  }
  return returnRes
}

export const fetchLpAmountInBigNumber = async () => {
  const returnRes = {}
  const _calls = lpTokens.map(async (element) => {
    returnRes[`${element.symbol}`] = 0
    const contract = await getLpContract(element.address)
    return contract.lpPriceUno()
  })

  const prices = await Promise.all(_calls).then((res) => {
    return res
  })

  for (let i = 0; i < lpTokens.length; i++) {
    returnRes[`${lpTokens[i].symbol}`] = getBalanceAmount(new BigNumber(prices[i].toString()), lpTokens[i].decimals)
  }

  return returnRes
}

export const fetchLpAmountInBigNumberWithDecimals = async () => {
  const returnRes = {}
  const _calls = lpTokens.map(async (element) => {
    returnRes[`${element.symbol}`] = 0
    const contract = await getLpContract(element.address)
    return contract.lpPriceUno()
  })

  const prices = await Promise.all(_calls).then((res) => {
    return res
  })
  for (let i = 0; i < lpTokens.length; i++) {
    returnRes[`${lpTokens[i].symbol}`] = new BigNumber(prices[i].toString())
  }
  return returnRes
}

export const fetchPoolInfos = async () => {
  const ethPools = poolsConfig.filter((_) => _.ethPool || (!_.ethPool && !_.oldPool))
  const _calls = ethPools.map(async (_pool) => {
    const poolContract = getContract(
      _pool.contractAddress[_pool.chainId],
      ssipAbi,
      new JsonRpcProvider(getRpcUrl(_pool.chainId)),
    )

    return await poolContract.poolInfo()
  })

  const values = await Promise.all(_calls).then((value) => {
    return value
  })

  const perBlockInJSON = values.map((_) => {
    return _.unoMultiplierPerBlock ? _.unoMultiplierPerBlock.toJSON() : _.toJSON()
  })

  const perBlocks = [
    ...perBlockInJSON,
    {
      type: 'BigNumber',
      hex: `0x00`,
    },
    {
      type: 'BigNumber',
      hex: `0x00`,
    },
    {
      type: 'BigNumber',
      hex: `0x00`,
    },
  ]

  return poolsConfig.map((p, index) => ({
    poolId: p.poolId,
    rewardPerBlock: perBlocks[index],
  }))
}

export const fetchPoolLockTime = async () => {
  const ethPools = poolsConfig.filter((_) => _.ethPool || (!_.ethPool && !_.oldPool))

  const _calls = ethPools.map(async (_pool) => {
    const poolContract = getContract(
      _pool.contractAddress[_pool.chainId],
      ssipAbi,
      new JsonRpcProvider(getRpcUrl(_pool.chainId)),
    )

    return poolContract.lockTime()
  })

  const values = await Promise.all(_calls).then((value) => {
    return value
  })

  const lockTimesForEthPool = values.map((_) => {
    return _.toString() / 3600
  })

  const lockTimes = [...lockTimesForEthPool, 365 * 24, 365 * 24, 365 * 24]

  return poolsConfig.map((p, index) => ({
    poolId: p.poolId,
    lockTime: lockTimes[index],
  }))
}
