import { AppNetworkId } from '@/config/chains'

export interface Address {
  1?: string
  11155111?: string
  97?: string
  56?: string
  57000?: string
  570?: string
}

export interface Token {
  symbol: string
  address?: Address
  decimals?: number
  projectLink?: string
  busdPrice?: string
}

export enum PoolCategory {
  'COMMUNITY' = 'Community',
  'CORE' = 'Core',
  'AUTO' = 'Auto',
}

export interface PoolConfig {
  poolId: number
  earningToken: Token
  stakingToken: Token
  contractAddress: Address
  poolCategory: PoolCategory
  tokenPerBlock: string
  sortOrder?: number
  harvest?: boolean
  isFinished?: boolean
  enableEmergencyWithdraw?: boolean
  distribution?: number
  rewardAddress?: Address
  riskPoolAddress?: Address
  poolType?: boolean
  ethPool?: boolean
  lpPool?: boolean
  isWithdrawOnly?: boolean
  oldPool?: boolean
  poolName?: string
  lpSymbol?: string
  isSSRP?: boolean
  ethNativePool?: boolean
  isDisabled?: boolean
  stakedTokenPriceInUsd?: number
  rewardTokenPriceInUsd?: number

  chainId?: AppNetworkId
}

export type Images = {
  lg: string
  md: string
  sm: string
  ipfs?: string
}
