import type { AddEthereumChainParameter } from '@web3-react/types'

const ETH: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Ether',
  symbol: 'ETH',
  decimals: 18,
}

const BNB: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Binance Smart Chain',
  symbol: 'BNB',
  decimals: 18,
}

const RolluxTestnet: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Rollux Testnet',
  symbol: 'SYS',
  decimals: 18,
}

const Rollux: AddEthereumChainParameter['nativeCurrency'] = {
  name: 'Rollux',
  symbol: 'SYS',
  decimals: 18,
}

export enum AppNetworkId {
  MAINNET = 1,
  SEPOLIA = 11155111,
  BSC = 56,
  BSC_TESTNET = 97,
  ROLLUX_TESTNET = 57000,
  ROLLUX = 570,
}

export const NetworkInfo: { [key in AppNetworkId]: { name: string; symbol: string } } = {
  [AppNetworkId.MAINNET]: { name: 'Ethereum', symbol: 'ETH' },
  [AppNetworkId.SEPOLIA]: { name: 'Sepolia', symbol: 'ETH' },
  [AppNetworkId.BSC]: { name: 'Binance Smart Chain', symbol: 'BNB' },
  [AppNetworkId.BSC_TESTNET]: { name: 'BSC Testnet', symbol: 'BNB' },
  [AppNetworkId.ROLLUX_TESTNET]: { name: 'Rollux Testnet', symbol: 'SYS' },
  [AppNetworkId.ROLLUX]: { name: 'Rollux', symbol: 'SYS' },
}

interface BasicChainInformation {
  urls: string[]
  name: string
  blockExplorerUrls: string[]
}

interface ExtendedChainInformation extends BasicChainInformation {
  nativeCurrency: AddEthereumChainParameter['nativeCurrency']
  blockExplorerUrls: AddEthereumChainParameter['blockExplorerUrls']
}

function isExtendedChainInformation(
  chainInformation: BasicChainInformation | ExtendedChainInformation,
): chainInformation is ExtendedChainInformation {
  return !!(chainInformation as ExtendedChainInformation).nativeCurrency
}

export function getAddChainParameters(chainId: number): AddEthereumChainParameter | number {
  const chainInformation = CHAINS[chainId]
  if (isExtendedChainInformation(chainInformation)) {
    return {
      chainId,
      chainName: chainInformation.name,
      nativeCurrency: chainInformation.nativeCurrency,
      rpcUrls: chainInformation.urls,
      blockExplorerUrls: chainInformation.blockExplorerUrls,
    }
  }
  return chainId
}

type ChainConfig = { [chainId: number]: BasicChainInformation | ExtendedChainInformation }

export const MAINNET_CHAINS: ChainConfig = {
  1: {
    urls: ['https://nd-895-598-990.p2pify.com/9491d427e516251a595a8307fa8475b8'],
    name: 'Mainnet',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://etherscan.io'],
  },
  56: {
    urls: [
      'https://bsc-dataseed.binance.org/',
      'https://bsc.publicnode.com',
      'https://bsc.blockpi.network/v1/rpc/public',
    ],
    name: 'Binance Smart Chain',
    nativeCurrency: BNB,
    blockExplorerUrls: ['https://bscscan.com'],
  },
  570: {
    urls: ['https://rpc1.rollux.com'],
    name: 'Rollux',
    nativeCurrency: Rollux,
    blockExplorerUrls: ['https://explorer.rollux.com'],
  },
}

export const TESTNET_CHAINS: ChainConfig = {
  11155111: {
    urls: ['https://ethereum-sepolia.core.chainstack.com/99f865b36ba3be8d02915c47cb400c26'],
    name: 'Sepolia',
    nativeCurrency: ETH,
    blockExplorerUrls: ['https://sepolia.etherscan.io'],
  },
  97: {
    urls: ['https://bsc-testnet.public.blastapi.io'].filter(Boolean),
    name: 'BSC Testnet',
    nativeCurrency: BNB,
    blockExplorerUrls: ['https://testnet.bscscan.com'],
  },
  57000: {
    urls: ['https://rpc-tanenbaum.rollux.com'],
    nativeCurrency: RolluxTestnet,
    name: 'Rollux Testnet',
    blockExplorerUrls: ['https://rollux.tanenbaum.io'],
  },
}

export const CHAINS: ChainConfig = {
  ...MAINNET_CHAINS,
  ...TESTNET_CHAINS,
}

export function getExplorerUrl(chainId: number, data: string, type: 'tx' | 'address' = 'tx'): string {
  const chain = CHAINS[chainId]
  if (chain) {
    const explorer = chain.blockExplorerUrls[0]
    if (explorer) {
      return `${explorer}/${type}/${data}`
    }
  }
  return ''
}

export const URLS: { [chainId: number]: string[] } = Object.keys(CHAINS).reduce<{ [chainId: number]: string[] }>(
  (accumulator, chainId) => {
    const validURLs: string[] = CHAINS[Number(chainId)].urls

    if (validURLs.length) {
      accumulator[Number(chainId)] = validURLs
    }

    return accumulator
  },
  {},
)
