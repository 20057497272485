import { BSC_DEFAULT_CHAIN_ID, ETH_DEFAULT_CHAIN_ID } from '@/config'
import sample from 'lodash/sample'
import { AppNetworkId } from '@/config/chains'

const nodesOnBsc = {
  56: [
    'https://bsc-dataseed3.ninicoin.io/',
    'https://bsc-dataseed.binance.org/',
    'https://bsc-dataseed1.defibit.io/',
    'https://bsc-dataseed1.ninicoin.io/',
  ],
  97: ['https://data-seed-prebsc-2-s1.binance.org:8545'],
}

const rpcUrls: { [key in AppNetworkId]: string[] } = {
  [AppNetworkId.MAINNET]: ['https://nd-895-598-990.p2pify.com/9491d427e516251a595a8307fa8475b8'],
  [AppNetworkId.SEPOLIA]: ['https://ethereum-sepolia.core.chainstack.com/99f865b36ba3be8d02915c47cb400c26'],
  [AppNetworkId.BSC]: [
    'https://bsc-dataseed3.ninicoin.io/',
    'https://bsc-dataseed.binance.org/',
    'https://bsc-dataseed1.defibit.io/',
    'https://bsc-dataseed1.ninicoin.io/',
  ],
  [AppNetworkId.BSC_TESTNET]: ['https://data-seed-prebsc-2-s1.binance.org:8545'],
  [AppNetworkId.ROLLUX_TESTNET]: ['https://rpc-tanenbaum.rollux.com'],
  [AppNetworkId.ROLLUX]: ['https://rpc1.rollux.com'],
}

/**
 * @deprecated
 */
export const getNodeUrlOnBsc = () => {
  return sample(nodesOnBsc[BSC_DEFAULT_CHAIN_ID])
}

export const getRpcUrl = (chainId: AppNetworkId) => {
  return sample(rpcUrls[chainId])
}

export const getInfuraRpcUrl = () => {
  return getRpcUrl(ETH_DEFAULT_CHAIN_ID)
}

// Array of available nodes to connect to
export const nodes = [getInfuraRpcUrl()]
const getNodeUrl = () => {
  return sample(nodes)
}

export default getNodeUrl
