const tokens = {
  eth: {
    symbol: 'ETH',
    projectLink: '',
  },
  wsys: {
    symbol: 'WSYS',
    address: {
      570: '0x4200000000000000000000000000000000000006',
    },
    decimals: 18,
    projectLink: '',
  },
  weth: {
    symbol: 'ETH',
    address: {
      1: '0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2',
      4: '0xc778417E063141139Fce010982780140Aa0cD5Ab',
      11155111: '0x7b79995e5f793a07bc00c21412e50ecae098e7f9',
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      97: '0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd',
      570: '0x4200000000000000000000000000000000000006',
    },
    decimals: 18,
    projectLink: '',
  },
  usdc: {
    symbol: 'USDC',
    address: {
      1: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      4: '0x68C34F54E63664019f59029b9dE344d6219C7758',
      11155111: '0xdb2587deb089c8f914ba6fedf1e3d3cb8660a015',
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      97: '0x62562D21F071141DD0f0821ad639c1cBFaAd4145',
      57000: '0xa68E417905ACdEdC32ae8DA9113a6d4d2b6B2F30',
    },
    decimals: 6,
    projectLink: 'https://www.centre.io/usdc',
  },
  usdt: {
    symbol: 'USDT',
    address: {
      1: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
      4: '0x40c035016AD732b6cFce34c3F881040B6C6cf71E',
      11155111: '0xaa8e23fb1079ea71e0a56f48a2aa51851d8433d0',
      56: '0x68C34F54E63664019f59029b9dE344d6219C7758',
      97: '0x892528707DF43773782F2564FbCd5Dc7441B86EB',
      570: '0x28c9c7Fb3fE3104d2116Af26cC8eF7905547349c',
    },
    decimals: 6,
    projectLink: 'https://tether.to/en/',
  },
  usdcbsc: {
    symbol: 'USDC',
    address: {
      1: '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48',
      4: '0x68C34F54E63664019f59029b9dE344d6219C7758',
      5: '0xb1Ce55d27FaF8D4499b840A3EDf509E4df43f9E1',
      56: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d',
      97: '0x62562D21F071141DD0f0821ad639c1cBFaAd4145',
    },
    decimals: 18,
    projectLink: 'https://www.centre.io/usdc',
  },
  uno: {
    symbol: 'UNO',
    address: {
      1: '0x474021845C4643113458ea4414bdb7fB74A01A77',
      4: '0x53fb43BaE4C13d6AFAD37fB37c3fC49f3Af433F5',
      11155111: '0xF75C8E49831c055a88957adbc97450f778460FD9',
      56: '0x474021845C4643113458ea4414bdb7fB74A01A77',
      97: '0x28E8bf707CD1C9D1a9F9fEd5563BB2b399dce90E',
      57000: '0x1E61F32cBc30d919AdC92CA1eD92dA3fd115a530',
      570: '0x570baA32dB74279a50491E88D712C957F4C9E409',
    },
    decimals: 18,
    projectLink: 'https://unore.io/',
  },
  horde: {
    symbol: 'HORDE',
    address: {
      1: '0x474021845C4643113458ea4414bdb7fB74A01A77',
      4: '0x53fb43BaE4C13d6AFAD37fB37c3fC49f3Af433F5',
      5: '0x491e7B202Ca6eB8beFb5eC4C6a3D68ce2159dcF2',
      56: '0x0069FF45ea9F7c28E0A2dEDb9BEf8144af8131BC',
      97: '0x199c66ab7273Fa711F5CCe0fbd92f3Ff4a1f2939',
    },
    decimals: 18,
    projectLink: 'https://horde.io/',
  },
  ethlp: {
    symbol: 'SSIP-ETH LP',
    address: {
      1: '0x929F524473D7B86acc0ADD87B1874Bdf63Cf0Ab1',
      5: '0x73EdfE2b622A9d7453DbaA2a10766FB98a71Af3A',
      56: '',
      97: '0xBC655a1aB7E9FF9a62b4187e45c48DA5037C264a',
    },
    decimals: 18,
    projectLink: '',
  },
  unolp: {
    symbol: 'SSIP-UNO LP',
    address: {
      1: '0x8978d08bd89B9415eB08A4D52C1bDDf070F19fA2',
      5: '0x449deEAabaa108C20Aa49f15Ba97C04C7D1d0ABd',
      56: '',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  ssrplp: {
    symbol: 'SSRP-UNO LP',
    address: {
      1: '0x14c019e3f1Dfe62E0137F25eF75c77e227d61629',
      5: '0x36Bc245184D2acfEfCA17c582CA356d81Ac7788f',
      56: '',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
    },
    decimals: 18,
    projectLink: '',
  },
}

export const usdPriceTokenList = [
  {
    symbol: 'ETH',
    id: 'weth',
  },
  {
    symbol: 'USDC',
    id: 'usd-coin',
  },
  {
    symbol: 'UNO',
    id: 'uno-re',
  },
  {
    symbol: 'USDT',
    id: 'tether',
  },
  {
    symbol: 'WSYS',
    id: 'wrapped-syscoin',
  },
]

export const lpTokens = [
  {
    symbol: 'SSIP-ETH LP',
    originTokenSymbol: 'ETH',
    address: {
      1: '0x929F524473D7B86acc0ADD87B1874Bdf63Cf0Ab1',
      11155111: '0x0cd3380C21F05267eBfA84c566FE4f677aE19C81',
      56: '0xBC655a1aB7E9FF9a62b4187e45c48DA5037C264a',
      97: '',
    },
    decimals: 18,
    projectLink: '',
  },
  {
    symbol: 'SSIP-UNO LP',
    originTokenSymbol: 'UNO',
    address: {
      1: '0x8978d08bd89B9415eB08A4D52C1bDDf070F19fA2',
      11155111: '0x109c0e5aD787af813c9C606B68d5A22722814C3c',
      56: '',
      97: '0x45f22138dfACfFf1e574c0B0f7fe8BBF04eF86F7',
    },
    decimals: 18,
    projectLink: '',
  },
  {
    symbol: 'SSIP-USDT LP',
    originTokenSymbol: 'USDT',
    address: {
      1: '0x442e9fe958202Dc29d7018c1AA47479F2159D8a0',
      11155111: '0xF16788c35fE062A52f33E81a54E8c4603f7c912D',
      56: '',
      97: '0x2c10C901a81096F78E25Ac68D2c0b849c879420D',
    },
    decimals: 18,
    projectLink: '',
  },
  {
    symbol: 'SSIP-USDC LP',
    originTokenSymbol: 'USDC',
    address: {
      1: '0xF37c0901662f39039AFBd3c2546e3141c091e014',
      11155111: '0xf6df4aFa07bC8C1c434b7bc72631117f6b22927F',
      56: '',
      97: '0x03c626BFb9F1BB3C899109375DDAb0a81eAe3756',
    },
    decimals: 18,
    projectLink: '',
  },
  {
    symbol: 'SSRP-UNO LP',
    originTokenSymbol: 'UNO',
    address: {
      1: '0x8978d08bd89B9415eB08A4D52C1bDDf070F19fA2',
      5: '0x36Bc245184D2acfEfCA17c582CA356d81Ac7788f',
      56: '',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
    },
    decimals: 18,
    projectLink: '',
  },
]

export const currencySymbols = {
  '0x40c035016AD732b6cFce34c3F881040B6C6cf71E': 'USDT',
}

export const NOT_SUPPORTED_TOKENS_IN_TRUSTWALLET = [
  '0xA6156492fC79616035F644C71b01e3099819F8EC',
  '0xD85AD783cc94bd04196a13DC042A3054a9B52210',
  '0x90f1bDf7F72102B2724b4d2D9c12f00270E4D376',
  '0x43633bDb2675aDaB99CE3059D734b92a1deDAb2b',
  '0x1eECc8C8298ed9Bd46c147D44E2D7A7BfACE2034',
  '0xa22134b7b18F1207414B69fcb3bdC6C6f18d7a78',
  // '0x6e9730EcFfBed43fD876A264C982e254ef05a0DE',
]

export const BLACK_LIST_SMART_CONTACTS = [
  '0x2260fac5e5542a773aa44fbcfedf7c193bc2c599', // WBTC
]

export default tokens
