import tokens from './tokens'
import { PoolCategory, PoolConfig } from './types'
import { AppNetworkId } from '@/config/chains'
import { REACT_APP_CHAIN_ID } from '@/Cover-Portal/constants'

const pools: PoolConfig[] = [
  {
    poolId: 0,
    stakingToken: tokens.usdcbsc,
    earningToken: tokens.uno,
    contractAddress: {
      1: '0xa476b3F7333796D4565a3D3666D54cF8557F0169',
      97: '0x3E8ca73B00164E79D7175abDc76dD49d63d8d076',
      56: '0x99EBb2865760325a078E8AEbE10D3bd066e7b79e',
    },
    rewardAddress: {
      1: '0x0451863C4dc8480D95E9Fb87D73D4f427A671eB6',
      97: '0xa8D7C519D2A7609743c2888025ee34237ff3c1f5',
      56: '0xfEa782FC55b814EB5611406D857Ca4e4F12d81f8', // '0x5D75249C121681578a8c387AfFd196A31Ae15c46',
    },
    riskPoolAddress: {
      1: '0x920D510D5c70C01989b66f4e24687Dddb988DdAe',
      97: '0xe38BDdd1c8F6C7b643fA7A854c1e721A1c82C997',
      56: '0xabb83630993984C54fd60650F5A592407C51e54b',
    },
    poolName: 'Zeus(v2) Pool',
    lpSymbol: 'SSIP-USDC LP',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    isWithdrawOnly: true,
    distribution: 0,
    poolType: true,
    ethPool: false,
    lpPool: false,
    oldPool: false,
    isSSRP: false,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
    chainId: AppNetworkId.BSC,
  },
  {
    poolId: 1,
    stakingToken: tokens.uno,
    earningToken: tokens.uno,
    contractAddress: {
      1: '0xa476b3F7333796D4565a3D3666D54cF8557F0169',
      11155111: '0xFeA94c1360B736B8E7ad501F4437BCe7707c486f',
      97: '0x5013FB7063b5050f344D698d0e565cCD8BA24751',
      56: '0xa64D680DdDFb738c7681ED18CA1E289fB0e6b24f',
    },
    rewardAddress: {
      1: '0x0451863C4dc8480D95E9Fb87D73D4f427A671eB6',
      11155111: '0x39a55291f1083Da03E9a8A3F078D49a9168d66cc',
      97: '0x3c6cBaAdd2778ABc271Bbe2a02Aa8C8274911333',
      56: '0xaE78a4aAeb7b37d69f42560f7F27ff9c0994DA73',
    },
    riskPoolAddress: {
      1: '0x920D510D5c70C01989b66f4e24687Dddb988DdAe',
      11155111: '0xf6df4aFa07bC8C1c434b7bc72631117f6b22927F',
      97: '0x375ddB6Ab10c553c3FcED71a4EeDaF09d17a2170',
      56: '0xeF21cB3eE91EcB498146c43D56C2Ef9Bae6B7d53',
    },
    poolName: 'AresBSC Pool',
    lpSymbol: 'SSIP-UNO LP',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    isWithdrawOnly: true,
    distribution: 0,
    poolType: true,
    ethPool: false,
    lpPool: false,
    oldPool: false,
    isSSRP: false,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
    chainId: AppNetworkId.BSC,
  },
  // {
  //   poolId: 2,
  //   stakingToken: tokens.horde,
  //   earningToken: tokens.uno,
  //   contractAddress: {
  //     1: '0xa476b3F7333796D4565a3D3666D54cF8557F0169',
  //     4: '0xd7fFE6d63f7a64260943BC0327065F13F8Ac0f47',
  //     97: '0xc75e610A640c88F6cd183247DB469Ee938C209D5',
  //     56: '0xBD1105Ce524828f15d7da3CAF098c8E42D0Fbf31',
  //   },
  //   rewardAddress: {
  //     1: '0x0451863C4dc8480D95E9Fb87D73D4f427A671eB6',
  //     4: '0x35893E75514c8e3D12603778531Ea3e383376eC9',
  //     97: '0x36137dADD65abA3747f8FE82A1E98F8f770E03b2',
  //     56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
  //   },
  //   riskPoolAddress: {
  //     1: '0x920D510D5c70C01989b66f4e24687Dddb988DdAe',
  //     4: '0x09Ae34DfB11BAF89b7fe727557E98a3AF94D30e5',
  //     97: '0x901FD16932F009c4c26517f51ff9E10E8c877a79',
  //     56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
  //   },
  //   poolName : "Thanatos Pool",
  //   lpSymbol: 'SSIP-USDC LP',
  //   poolCategory: PoolCategory.CORE,
  //   harvest: true,
  //   tokenPerBlock: '10',
  //   sortOrder: 1,
  //   isFinished: false,
  //   distribution: 0,
  //   poolType: true,
  //   ethPool: false,
  //   lpPool: false,
  //   oldPool: false,
  //   stakedTokenPriceInUsd: 0,
  //   rewardTokenPriceInUsd: 0,
  // },
  {
    poolId: 2,
    stakingToken: tokens.usdt,
    earningToken: tokens.uno,
    contractAddress: {
      1: '0x2ce13703656A7b9a10786F390c2fFa2D07650EbB',
      11155111: '0x1eE444E51b0d4aE7229c5B678a3A7Af7e8EaD4a2',
      97: '0xA7954dDf9FDA1bf8FF80369Bcf2500c3A4aF4B8B',
      56: '0xBD1105Ce524828f15d7da3CAF098c8E42D0Fbf31',
    },
    rewardAddress: {
      1: '0x4a84BDBd91BBb26Ac6AB5ba7840675088F400B11',
      11155111: '0x8CFD0e39118aF9d92571F3B9814728054F27cA82',
      97: '0xc22A3bB80B50FebF3bD57AD072cbCD771dDe6AdB',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0x442e9fe958202Dc29d7018c1AA47479F2159D8a0',
      11155111: '0xF16788c35fE062A52f33E81a54E8c4603f7c912D',
      97: '0xaCAc67eCb02E22E1D43dBf948FCe626997b1C9EB',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    poolName: 'Hercules Pool',
    lpSymbol: 'SSIP-USDT LP',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    distribution: 0,
    poolType: true,
    ethPool: true,
    lpPool: false,
    oldPool: false,
    isSSRP: false,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
    chainId: REACT_APP_CHAIN_ID === '1' ? AppNetworkId.MAINNET : AppNetworkId.SEPOLIA,
  },
  {
    poolId: 3,
    stakingToken: tokens.usdc,
    earningToken: tokens.uno,
    contractAddress: {
      1: '0x31EDcC44536f91E0f84c216C792Ff9a9B477fDC9',
      11155111: '0x3A83bD2e395EaBdD534c8f1EbB283B67418Abe31',
      97: '0xc7f793b735dC6d86e819E3693A93BaB27aDAc820',
      56: '0xBD1105Ce524828f15d7da3CAF098c8E42D0Fbf31',
    },
    rewardAddress: {
      1: '0xAD2dDbE4001F91784e82c0f7ba8152aA335d440F',
      11155111: '0x39a55291f1083Da03E9a8A3F078D49a9168d66cc',
      97: '0xE512CBa762BF5B5700B9a55115584C2C3505e95D',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0xF37c0901662f39039AFBd3c2546e3141c091e014',
      11155111: '0xf6df4aFa07bC8C1c434b7bc72631117f6b22927F',
      97: '0x03c626BFb9F1BB3C899109375DDAb0a81eAe3756',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    poolName: 'Aphrodite Pool',
    lpSymbol: 'SSIP-USDC LP',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    distribution: 0,
    poolType: true,
    ethPool: true,
    lpPool: false,
    oldPool: false,
    isSSRP: false,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
    chainId: REACT_APP_CHAIN_ID === '1' ? AppNetworkId.MAINNET : AppNetworkId.SEPOLIA,
  },
  {
    poolId: 4,
    stakingToken: tokens.weth,
    earningToken: tokens.uno,
    contractAddress: {
      1: '0xE34DBacff7078dA18260d9321982E588AA30d4B6',
      11155111: '0x61cf346a0BF8064D35A58FEfc873fC8241d48FE4',
      97: '0x648371Af2d66bA8C797D0E698029E009E3A92778',
      56: '0xBD1105Ce524828f15d7da3CAF098c8E42D0Fbf31',
    },
    rewardAddress: {
      1: '0x69485Fd7baf4bA8BE375Be0528d4DB5616DeD925',
      11155111: '0x25997F9a3d52c66c95D500aA3f49380E9b5685BD',
      97: '0xD44C68F591362E632a992dB259134848e32632F7',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0x929F524473D7B86acc0ADD87B1874Bdf63Cf0Ab1',
      11155111: '0x0cd3380C21F05267eBfA84c566FE4f677aE19C81',
      97: '0xAb4De094830171b8feaD2f02450fd767D244681f',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    poolName: 'Selene Pool',
    lpSymbol: 'SSIP-ETH LP',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    isWithdrawOnly: true,
    distribution: 0,
    poolType: true,
    ethPool: true,
    lpPool: false,
    oldPool: false,
    isSSRP: false,
    ethNativePool: true,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
    chainId: REACT_APP_CHAIN_ID === '1' ? AppNetworkId.MAINNET : AppNetworkId.SEPOLIA,
  },
  {
    poolId: 5,
    stakingToken: tokens.uno,
    earningToken: tokens.uno,
    contractAddress: {
      1: '0xd87a4214EDa400Ed376EaaC0aEd9d1414D71581C',
      11155111: '0xFeA94c1360B736B8E7ad501F4437BCe7707c486f',
      97: '0xf2aC49B27644185E2d0E26648314577a76FBe0F7',
      56: '0xBD1105Ce524828f15d7da3CAF098c8E42D0Fbf31',
    },
    rewardAddress: {
      1: '0x26bc782a4CfF6E3E92CF5Ae6A8b50971C0b309E8',
      11155111: '0x58c97878fE7986dd33608D0eAc24f65fb7589bE5',
      97: '0x88Ba79DBacb1A6300b9E0Bf72eB846C2fB27bAcF',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0x8978d08bd89B9415eB08A4D52C1bDDf070F19fA2',
      11155111: '0x109c0e5aD787af813c9C606B68d5A22722814C3c',
      97: '0x867D99cc4bd0F6cBA22B9fdC315981B5dFA9122d',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    lpSymbol: 'SSIP-UNO LP',
    poolName: 'Ares Pool',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    distribution: 250000,
    poolType: true,
    ethPool: true,
    lpPool: false,
    oldPool: false,
    isSSRP: false,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
    chainId: REACT_APP_CHAIN_ID === '1' ? AppNetworkId.MAINNET : AppNetworkId.SEPOLIA,
  },
  {
    poolId: 6,
    stakingToken: tokens.uno,
    earningToken: tokens.uno,
    contractAddress: {
      1: '0xe86B104B12546BF9c87329f8936CaF2c4340b728',
      11155111: '0xFeA94c1360B736B8E7ad501F4437BCe7707c486f',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
      56: '0xBD1105Ce524828f15d7da3CAF098c8E42D0Fbf31',
    },
    rewardAddress: {
      1: '0xAB653c99eE7ACE6b8A63F5aD44a3231B3E39b649',
      11155111: '0xd9173789e1Bb5a7Fa72fd30506938e29EC53dBb1',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0x076E2A501FD0DA41E5A659aB664b2B6792B80Fa2',
      11155111: '0x109c0e5aD787af813c9C606B68d5A22722814C3c',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    poolName: 'Poseidon Pool',
    lpSymbol: 'SSRP-UNO LP',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    distribution: 250000,
    poolType: true,
    isSSRP: true,
    ethPool: true,
    lpPool: false,
    oldPool: false,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
    chainId: REACT_APP_CHAIN_ID === '1' ? AppNetworkId.MAINNET : AppNetworkId.SEPOLIA,
  },
  {
    poolId: 7,
    stakingToken: tokens.wsys,
    earningToken: tokens.uno,
    contractAddress: {
      570: '0x3B61743180857c9D898c336b1604f4742887aa74',
    },
    rewardAddress: {
      570: '0xC7447D70B03972DAD9e97e41b1dBBd4D38d60b0D',
    },
    riskPoolAddress: {
      570: '0x7393310FdC8ed40B35D2afD79848BC7166Ae0474',
    },
    poolName: 'Plutus Pool',
    lpSymbol: 'SSIP-USDT LP',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    distribution: 0,
    poolType: true,
    ethPool: false,
    lpPool: false,
    oldPool: false,
    isSSRP: false,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
    chainId: AppNetworkId.ROLLUX,
  },
  {
    poolId: 8,
    stakingToken: tokens.uno,
    earningToken: tokens.uno,
    contractAddress: {
      570: '0xb313807aAdceADc15bCCef8dD7f0FF1AD3cf5148',
    },
    rewardAddress: {
      570: '0x0F62C651b0105a3F6BbB525bf442070f23152d27',
    },
    riskPoolAddress: {
      570: '0x8685C2b4D2024805a1FF6831Bc4cc8569457811D',
    },
    poolName: 'Athena Pool',
    lpSymbol: 'SSIP-WSYS LP',
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: false,
    distribution: 0,
    poolType: true,
    ethPool: false,
    lpPool: false,
    oldPool: false,
    isSSRP: false,
    isDisabled: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
    chainId: AppNetworkId.ROLLUX,
  },
  {
    poolId: 9,
    stakingToken: tokens.usdcbsc,
    earningToken: tokens.usdcbsc,
    contractAddress: {
      1: '0x1eECc8C8298ed9Bd46c147D44E2D7A7BfACE2034',
      97: '0xc1686c63D19ff15fB14C96fBd4d433e5ea748e03',
      56: '0xEcE9f1A3e8bb72b94c4eE072D227b9c9ba4cd750',
    },
    rewardAddress: {
      1: '0x0451863C4dc8480D95E9Fb87D73D4f427A671eB6',
      97: '0x71Fdc22F7522D7a07d1509205df0DEec538180f2',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0x920D510D5c70C01989b66f4e24687Dddb988DdAe',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: true,
    distribution: 250000,
    poolType: true,
    poolName: 'Zeus Pool',
    ethPool: false,
    lpPool: false,
    oldPool: true,
    isSSRP: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
    chainId: AppNetworkId.BSC,
  },
  {
    poolId: 10,
    stakingToken: tokens.usdcbsc,
    earningToken: tokens.usdcbsc,
    contractAddress: {
      1: '0x1eECc8C8298ed9Bd46c147D44E2D7A7BfACE2034',
      97: '0xE876915A98d9181B3AE82E92F17bB0cE318E5750',
      56: '0x0b5C802ecA88161B5daed08e488C83d819a0cD02',
    },
    rewardAddress: {
      1: '0x0451863C4dc8480D95E9Fb87D73D4f427A671eB6',
      97: '0x71Fdc22F7522D7a07d1509205df0DEec538180f2',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0x920D510D5c70C01989b66f4e24687Dddb988DdAe',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: true,
    distribution: 900000,
    poolType: true,
    poolName: 'Athena Pool',
    ethPool: false,
    lpPool: false,
    oldPool: true,
    isSSRP: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
    chainId: AppNetworkId.BSC,
  },
  {
    poolId: 11,
    stakingToken: tokens.usdcbsc,
    earningToken: tokens.usdcbsc,
    contractAddress: {
      1: '0x1eECc8C8298ed9Bd46c147D44E2D7A7BfACE2034',
      97: '0x9EF59B956aF99Ef460B009a2EFA7c38973540a99',
      56: '0x2cd32dF1C436f8dE6e09d1A9851945c56bcEd32a',
    },
    rewardAddress: {
      1: '0x0451863C4dc8480D95E9Fb87D73D4f427A671eB6',
      97: '0x71Fdc22F7522D7a07d1509205df0DEec538180f2',
      56: '0xc8FFf74f9AB50B68a727bf2c35afe3A3970910D3',
    },
    riskPoolAddress: {
      1: '0x920D510D5c70C01989b66f4e24687Dddb988DdAe',
      97: '0xCeeDd150b9B09a865E21acA29DD5F329BaF9c49d',
      56: '0xFC9a02a13B19F65219034AB03ADcD8CAdf275f35',
    },
    poolCategory: PoolCategory.CORE,
    harvest: true,
    tokenPerBlock: '10',
    sortOrder: 1,
    isFinished: true,
    distribution: 1320000,
    poolType: true,
    poolName: 'Artemis Pool',
    ethPool: false,
    lpPool: false,
    oldPool: true,
    isSSRP: false,
    stakedTokenPriceInUsd: 0,
    rewardTokenPriceInUsd: 0,
    chainId: AppNetworkId.BSC,
  },
]

export const premiumPool: PoolConfig = {
  poolId: null,
  stakingToken: tokens.usdc,
  earningToken: tokens.usdc,
  contractAddress: {
    1: '0x31d20E7d8bEaF36Cfe8369EB8FBC846B5466c983',
    11155111: '0xcb2B848AF2C87C6a5B213C6dB4259CfA95A7c7E3',
    97: '0x8a88F5A0EBC56E7eE94a0d8045CE4D873BBa51f0',
    56: '0xd85D5144718982bdD442E67d9810151a6AdF31c2',
    57000: '0x34c76160d79C6d0C678b0B48d40eC5ddc895f4ED',
  },
  poolCategory: PoolCategory.CORE,
  harvest: true,
  tokenPerBlock: '10',
  sortOrder: 1,
  isFinished: false,
}

interface Protocol {
  protocolId: number
  name?: string
}

export const protocols: Protocol[] = [
  {
    protocolId: 0,
  },
  {
    protocolId: 1,
  },
  {
    protocolId: 2,
  },
  {
    protocolId: 3,
  },
  {
    protocolId: 4,
  },
]

export default pools
