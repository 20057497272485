import { ChainId, poolsConfig } from '@/config/constants'
import addresses from '@/config/constants/contracts'
import { Address } from '@/config/constants/types'
import { BSC_DEFAULT_CHAIN_ID } from '@/config'

export const getAddress = (address: Address): string => {
  const chainId = Number(process.env.REACT_APP_CHAIN_ID)
  return address[chainId] ? address[chainId] : address[ChainId.MAINNET]
}

export const getAddressOnBsc = (address: Address): string => {
  return address[BSC_DEFAULT_CHAIN_ID]
}

export const getMulticallAddress = () => {
  return getAddress(addresses.multiCall)
}
export const getCohortAddress = () => {
  return getAddressOnBsc(addresses.cohort)
}

export const getPremiumPoolAddress = () => {
  return getAddress(addresses.premium)
}

export const getRiskPoolAddress = (poolId: number) => {
  const poolConfig = poolsConfig.find((p) => p.poolId === poolId)
  return poolConfig.ethPool ? getAddress(poolConfig.contractAddress) : getAddressOnBsc(poolConfig.contractAddress)
}

export const getSSRPAddress = () => {
  return getAddress(addresses.ssrp)
}

export const getUnoAddress = () => {
  return getAddress(addresses.uno)
}

export const getUsdcAddress = () => {
  return getAddress(addresses.usdc)
}
export const getUsdtAddress = () => {
  return getAddress(addresses.usdt)
}

export const getCapitalAgentAddress = (ethPool: boolean) => {
  return ethPool ? getAddress(addresses.capitalAgent) : getAddressOnBsc(addresses.capitalAgent)
}

export const getRewardAddress = (poolId: number) => {
  const pool = poolsConfig.find((p) => p.poolId === poolId)
  return getAddress(pool.rewardAddress)
}

export const getMigrationAddress = () => {
  return getAddressOnBsc(addresses.migration)
}

export const getTokenAddress = (symbol) => {
  return getAddress(addresses[`${symbol}`])
}

export const getSalesPolicyAddress = () => {
  return getAddress(addresses.salesPolicy)
}

export const getPayoutRequestETHAddress = () => {
  return getAddress(addresses.payoutRequestETH)
}

export const getPayoutRequestUNOAddress = () => {
  return getAddress(addresses.payoutRequestUNO)
}

export const getPayoutRequestUSDCAddress = () => {
  return getAddress(addresses.payouRequestUSDC)
}

export const getPayoutRequestUSDTAddress = () => {
  return getAddress(addresses.payoutRequestUSDT)
}

export const getLpUnoTokenAddress = (address) => {
  return getAddress(address)
}
